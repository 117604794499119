import { ApolloClient, ApolloProvider, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Query } from '@apollo/client/react/components';
import { getMainDefinition } from '@apollo/client/utilities';
import { CachePersistor } from 'apollo-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { get } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { INITIALIZE_WEBCHAT } from './actions/Webchat';
import possibleTypes from './assets/fragmentTypes.json';
import InteractContainer from './components/InteractContainer';
import ConfigProvider from './ConfigProvider';
import ChatUserReceiver from './utils/ChatUserReceiver';

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WS,
  })
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL,
  })
);

const cache = new InMemoryCache({
  possibleTypes,
});

const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});

const base64ToObject = base64 => {
  try {
    return JSON.parse(atob(base64));
  } catch (e) {
    return { ERROR: 'JSON parse failed' };
  }
};

const urlObj = base64ToObject(window.location.pathname.split('/')[1] || {});

const CHANNEL_ID = urlObj.channelId;
const hasNotifications = urlObj.hasNotifications;
const SESSION_DATA = urlObj.session;
const UPLOAD_ENABLED = !!urlObj.u;
const chatUserId = urlObj.c;
const slug = urlObj.slug;
const sessionId = urlObj.s
const welcomeMessage = urlObj.welcomeMessage
const allowRestartConversation = urlObj.allowRestartConversation
// const width = urlObj.w;
// const height = urlObj.h;

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  key: CHANNEL_ID,
});

persistor
  .restore()
  .then(() => {
    ReactDOM.render(
      <ApolloProvider client={client}>
        <Query query={INITIALIZE_WEBCHAT} variables={{ channelId: CHANNEL_ID }} fetchPolicy="network-only">
          {({ data, loading, error, subscribeToMore }) => {
            if (loading) {
              return null;
            }
            if (error) {
              return console.log('Error::', error.message) || <span>ERROR:: {error.message}</span>;
            }
            if (!data) {
              return null;
            }

            return (
              <ChatUserReceiver
                slug={slug}
                session={SESSION_DATA}
                persistor={persistor}
                channelId={CHANNEL_ID}
                chatUserId={chatUserId}
                subscribeToMore={subscribeToMore}
                saveHistory={get(data.theme, 'action.saveHistory')}
              >
                {({ user, isTyping, messagesFromSubscription }) => (
                  <ConfigProvider
                    theme={{ ...data.theme } || {}}
                    user={user.id}
                    channel={CHANNEL_ID}
                    session={SESSION_DATA}
                    hasNotifications={hasNotifications}
                    enableUpload={UPLOAD_ENABLED}
                    sessionId={sessionId}
                    welcomeMessage={welcomeMessage}
                    allowRestartConversation={allowRestartConversation}
                  >
                    <InteractContainer
                      user={user}
                      isTyping={isTyping}
                      messagesFromSubscription={messagesFromSubscription}
                    />
                  </ConfigProvider>
                )}
              </ChatUserReceiver>
            );
          }}
        </Query>
      </ApolloProvider>,
      document.getElementById('root')
    );
  })
  .catch(e => {
    console.error('Something went wrong when restoring the cache');
  });
